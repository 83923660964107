import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Introduction statement to the system`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@TechMagic/do-you-need-design-system-in-2019-32fb697b2126"
      }}>{`Article about benefits of a design system`}</a>{`
^^ Loop in this article to the introduction somehow`}</p>
    <h2>{`The Capital Brand`}</h2>
    <p><em parentName="p">{`Some of the brand stuff from the system guidelines about tone and all`}</em></p>
    <h2>{`Pieces to the Puzzle`}</h2>
    <p><em parentName="p">{`Information about the 3 parts - style, components and patterns`}</em></p>
    <h2>{`Building Capital`}</h2>
    <p><em parentName="p">{`Information about who builds and maintains capital`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      